<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    :disabledSaveButton="isEqual"
    @save="onSave('MailBoxes')"
    @cancel="onCancel('MailBoxes')"
  />
</template>

<script>
// mixins
import editableListItemEdit from '@/mixins/editableListItemEdit.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'MailBoxesEdit',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemEdit],

  data() {
    return {
      controllerName: 'MailBoxes',
      pageHeader: 'Редактирование почтового ящика',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Почтовые ящики',
          route: { name: 'MailBoxes' },
        },
        {
          text: 'Редактирование почтового ящика',
        },
      ],
      form: {},
      isLoadingPage: false,
    };
  },

  computed: {
    ...mapState({
      item: (state) => state.editableList.item,
      accounts: (state) => state.common.accounts.data,
    }),

    fields() {
      return [
        {
          tab: 'Основные',
          form: [
            {
              type: 'text',
              key: 'name',
              label: 'Название почт. ящика',
            },
            {
              type: 'text',
              key: 'server',
              label: 'Сервер',
            },
            {
              type: 'text',
              key: 'userName',
              label: 'Имя пользователя',
            },
            {
              type: 'select',
              key: 'accountId',
              label: 'ID пользователя',
              list: this.accounts
            },
            {
              type: 'text',
              key: 'password',
              label: 'Пароль пользователя',
            },
            {
              type: 'text',
              key: 'port',
              label: 'Номер порта',
            },
            {
              type: 'date',
              key: 'lastSuccessDate',
              label: 'Дата последнеей успешной синхронизации',
              disabled: true
            },
            {
              type: 'date',
              key: 'lastSyncDate',
              label: 'Дата последней синхронизации',
              disabled: true
            },
            {
              type: 'text',
              key: 'lastSyncError',
              label: 'Ошибка последней синхронизации',
              disabled: true
            },
            {
              type: 'checkbox',
              key: 'lastSyncWasSuccess',
              label: 'Последняя синхронизация была успшешной',
              disabled: true
            },
            {
              type: 'checkbox',
              key: 'disabled',
              label: 'Ящик неактивен',
            },
            {
              type: 'checkbox',
              key: 'useSsl',
              label: 'Использовует SSL',
            },
          ],
        },
        {
          tab: 'Почтовые группы',
          table: {
            headers: [
              {
                text: 'Название группы',
                order: 'name',
                alias: 'name',
              },
              {
                alias: 'actions',
              },
            ],
            items: this.form.messageGroups,
            key: 'messageGroups',
            modalFields: [
              {
                type: 'text',
                key: 'name',
                label: 'Название группы',
              },
            ],
            showEditButton: true,
            showDeleteButton: true,
            showCreateButton: true,
          },
        },
      ];
    },
  },

  created() {
    this.isLoadingPage = true;
    const accounts = this.getCommonList({ name: 'Accounts' });

    Promise.all([accounts]).then(() => {
      this.getItem({ name: this.controllerName, id: this.id })
        .then(() => {
          this.form = this.lodash.cloneDeep(this.item.data);
        })
        .finally(() => {
          this.isLoadingPage = false;
        });
    });
  },

  methods: {
    ...mapActions({
      getItem: 'editableList/getItem',
      updateItem: 'editableList/updateItem',
      getCommonList: 'common/getCommonList',
    }),
  },
};
</script>
